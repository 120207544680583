import './style.css'
// import './2d_script.js'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import Stats from 'stats.js'
import * as dat from 'lil-gui'
import { OutlineEffect } from 'three/examples/jsm/effects/OutlineEffect.js';

import { VRButton } from 'three/examples/jsm/webxr/VRButton.js'
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';

// import gsap from 'gsap'






// screen.orientation.lock('portrait');

// console.log("hola");


var overlaidArtist = {};
function showArtistDetail(e){

    let overlay = document.querySelector("#overlay");
    // console.log(e)

    // if(e.getAttribute("overlay") == "true"){
    if(overlay.getAttribute("enabled") == "true"){
        // e.setAttribute("overlay", false);
        overlay.setAttribute("enabled", "false");
        
        overlay.innerHTML = "";
        overlay.style.opacity = 0;
        // overlaidArtist.style.opacity = 1;
        

    }else{
        // e.setAttribute("overlay", true);        
        overlay.setAttribute("enabled", "true");
        // console.log(overlaidArtist);
        overlaidArtist = e;

        overlay.innerHTML = e.outerHTML;
        
        overlay.style.opacity = 1;
        // overlay.querySelector(".artistaText").style.transform = "translate(0, 0)";
        setTimeout(function(){
            // console.log(overlay.querySelector(".artistaText"))
            overlay.querySelector(".artistaText").style.transform = "translate(0, 0)";
        }, 0)
        setTimeout(function(){
            // console.log(overlay.querySelector(".artistaText"))
            overlay.querySelector(".artistaText").style.zIndex = 200
        }, 300)

        // transition: transform 0.5s ease-out;
        // e.style.opacity = 0;
        overlay.style.pointerEvents = "";
    }

    //old way
    // var a = e.querySelector(".artistaText")
    // console.log(a.style.display);
    // if(a.style.display == "" )
    //     a.style.display = "block";
    // else
    //     a.style.display = "";

}

function resizeWindow(){
    document.querySelector("#footerSpacer").style.height = document.querySelector("#footer").clientHeight + "px";
}

function tick2d(){

    let scrollY = window.scrollY;
    let height = window.innerHeight;
    let bgStickersOpacity = Math.max(1 - scrollY/height, 0.3);

    // let bodyHeight = document.querySelector("body").offsetHeight;

    // let offsetFooterY = Math.max(0, scrollY - (bodyHeight - height));
    // document.querySelector("#footer").style.transform = "translate(0, " + offsetFooterY + "px)";

    // document.querySelectorAll(".bgStickers").style.opacity = 1 - scrollY/height;
    if(document.querySelector("#home").getAttribute("visible") == "true"){
    document.querySelectorAll(".bgStickers").forEach((e) => {e.style.opacity = bgStickersOpacity});
    }else{
        document.querySelectorAll(".bgStickers").forEach((e) => {e.style.opacity = 0.15});
    }

    // console.log(scrollY/height)
    window.requestAnimationFrame(tick2d);
}

window.addEventListener("resize", function(){
    resizeWindow();
});


window.addEventListener("load", function(){
    console.log("loaded!");
    resizeWindow();
    adjustArtistLabels();
    tick2d();

    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.register("service-worker.js")
        .then(function(registration) {
            console.log('Service worker started');
            // alert('Service worker installed!');
        })
        .catch(function(error) {
            console.log('Service worker failed, error:', error);
        });
    }
});


let adjustArtistLabels = () => {
    // document.querySelectorAll(".artistWrapper")[1].querySelectorAll(".artistaName").forEach((e) => {
    document.querySelectorAll(".artistaName").forEach((e) => {
        // first, reset
        // because the target font size is calculated as a function of the current size
        e.style.fontSize = ""
        e.style.padding = ""
        
        let ratio = e.parentElement.offsetWidth/e.offsetWidth
        let fontSize =  Math.min(5*ratio*0.8, 5)
        e.style.fontSize = fontSize+"vmin"
        e.style.padding = "0.5vmin "+fontSize+"vmin"
        e.style.opacity = 1
    })
}

let toggleMenu = (enable) => {
    let e = document.querySelector("#menu")

    if(enable === undefined){
    if(e.getAttribute("enabled") == "true"){
        e.setAttribute("enabled", "false");
    }else{
        e.setAttribute("enabled", "true");
        }
    }else{
        e.setAttribute("enabled", enable);
    }
}

let debugUrl = false;
let rendererResize;

let gotoSection = (section) => {

    if(!section.length) return;

    if(section=="mapadebug"){
        section="mapa"
        debugUrl = true
    }

    let a = document.querySelectorAll(".section")
    a.forEach((e) => {
        e.setAttribute("visible", "false");
    })

    let e = document.querySelector("#"+section)
    if(!e) {
        section = "";
        e = document.querySelector("#home");
    }
    e.setAttribute("visible", "true");

    if(section == "" || section == "home"){
        adjustArtistLabels();
    }

    toggleMenu(false);

    window.history.pushState({}, "", section);

}

let faqToggle = (q) => {
    let qArray = document.querySelector("#faq").querySelectorAll(".question");

    qArray.forEach((q) => {
        q.prevOffsetTop = q.offsetTop;
    })

    let a = q.parentElement.querySelector(".answer");
    let v = a.getAttribute("visible") == "true" ? "false" : "true";
    a.setAttribute("visible", v);
    q.setAttribute("expand", v);

    qArray.forEach((q) => {
        let dy = - q.offsetTop + q.prevOffsetTop;
        q.style.transition = "transform 0s";
        q.style.transform = "translate3D(0, " + dy + "px, 0)";
        setTimeout(() => {
            q.style.transition = "transform 0.5s ease";
            q.style.transform = "translate3D(0, 0, 0)";
        }, 0)
    })
}

let postVidActiveForm = 0;
let showPostVid = (index) => {

    if(postVidActiveForm>0){   
        document.querySelector(".postvid-label.postvid-"+postVidActiveForm).setAttribute("selected", "false");
        document.querySelector(".gforms.postvid-"+postVidActiveForm).style.display = "none";
    }
    if(postVidActiveForm==index) {
        postVidActiveForm = 0;
        return;
    }

    postVidActiveForm = index;
    document.querySelector(".postvid-label.postvid-"+index).setAttribute("selected", "true");
    document.querySelector(".postvid-label.postvid-"+index).scrollIntoView({ block: "center" });
    document.querySelector(".gforms.postvid-" + index).style.display = "initial";
}

window.showArtistDetail = showArtistDetail;
window.toggleMenu = toggleMenu;
window.gotoSection = gotoSection;
window.faqToggle = faqToggle;
window.showPostVid = showPostVid;


//enable section from url path
let subUrl = window.location.pathname.substr(1);
gotoSection(subUrl);








// a partir de aqui va el codigo de la vista webgl

/**
 * Initial setup
 */
let setup = {
    outline: true,
    outlineThickness: 0.002,
    shadows: true,
    sunAzimuth: 300,
    sunAltitude: 45,
    cameraFov: 50,
    orthoCamera: true,
    cameraAutoRotate: false,
    cameraAutoRotateSpeed: 0.1,
    lookAt: 0
}

/**
 * Loaders
 */
const loadingBarElement = document.querySelector('.loading-bar')

let sceneReady = false
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        // Wait a little
        window.setTimeout(() =>
        {
            // Animate overlay
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

            // Update loadingBarElement
            // loadingBarElement.classList.add('ended')
            // loadingBarElement.style.transform = ''
        }, 500)

        window.setTimeout(() =>
        {
            sceneReady = true
        }, 2000)
    },

    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        // Calculate the progress and update the loadingBarElement
        const progressRatio = itemsLoaded / itemsTotal
        // loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)

const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')

const gltfLoader = new GLTFLoader(loadingManager)
gltfLoader.setDRACOLoader(dracoLoader)
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)

const textureLoader = new THREE.TextureLoader(loadingManager)


/**
 * Base
 */
// Debug
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Texture
const gradientTexture = textureLoader.load('textures/gradient.png')
gradientTexture.magFilter = THREE.NearestFilter

// Material
const treeToonMaterial = new THREE.MeshToonMaterial({
    color: 0xD5D872,
    gradientMap: gradientTexture
})

// let greenColor = new THREE.MeshToonMaterial({
//     color: new THREE.Color(0x777777)
// })

/**
 * Update all materials
 */
const updateMaterials = (scenePart) =>
{

    scenePart.traverse((child) =>
    {
        if(child instanceof THREE.Mesh)
        {
            let name = child.material.name;
                if(child.material.name == "borde"){
                    child.remove()
                    child.visible = false
                }else if(child.material.name == "black" || child.material.name == "fence"){
                    //materials with the outline effect disabled
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    mat.userData.outlineParameters = {
                        thickness: 0.1,
                        color: [ 0, 0, 0 ],
                        alpha: 1,
                        visible: false,
                        keepAlive: true
                    }
                    child.material = mat 
                    child.material.needsUpdate = true
                    child.castShadow = true
                    child.receiveShadow = false
                }else if(child.material.name == "Ground.001" || child.material.name.startsWith("suelo_")){
                    //materials that receive shadows
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    child.material = mat 
                    child.material.needsUpdate = true
                    child.castShadow = true
                    child.receiveShadow = true
                }else if(child.material.name.startsWith("lona_")){
                    //textured materials for stage canvas/billboards
                    child.material = new THREE.MeshToonMaterial({
                        map:child.material.map,
                        outputEncoding: THREE.sRGBEncoding
                    })
                }else if(child.material.name.startsWith("Copa")){
                    //transparent materials for "copa" mesh, with outline effect disabled
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    mat.userData.outlineParameters = {
                        thickness: 0.1,
                        color: [ 0, 0, 0 ],
                        alpha: 1,
                        visible: false,
                        keepAlive: true
                    }
                    child.material = mat 
                    child.material.side = THREE.DoubleSide
                    child.material.opacity = 0.15
                    child.material.transparent = true
                    child.castShadow = false
                    child.receiveShadow = false
                }else if(child.material.name.startsWith("Ojo") 
                        || child.material.name.startsWith("lengua") 
                        || child.material.name.startsWith("Boca") 
                        || child.material.name.startsWith("Dientes") 
                        || child.material.name.startsWith("Manos")
                        || child.material.name.startsWith("Material.002") 
                        || child.material.name.startsWith("Micro")){
                    //non-transparent materials for "copa" mesh, with outline effect disabled
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    mat.userData.outlineParameters = {
                        thickness: 0.1,
                        color: [ 0, 0, 0 ],
                        alpha: 1,
                        visible: false,
                        keepAlive: true
                    }
                    child.material = mat 
                    child.material.needsUpdate = true
                    child.castShadow = false
                    child.receiveShadow = false
                }else if(child.material.name.startsWith("Boca_transparente")){
                    //mouth material for "copa", with opacity zero
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    mat.userData.outlineParameters = {
                        thickness: 0.1,
                        color: [ 0, 0, 0 ],
                        alpha: 1,
                        visible: false,
                        keepAlive: true
                    }
                    child.material = mat 
                    child.material.opacity = 0
                    child.material.transparent = true
                    child.castShadow = false
                    child.receiveShadow = false
                }else if(child.material.name.startsWith("guitarra") || child.material.name.startsWith("Gusano")){
                    //materials for "gusano" mesh, with outline effect disabled
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    mat.userData.outlineParameters = {
                        thickness: 0.1,
                        color: [ 0, 0, 0 ],
                        alpha: 1,
                        visible: false,
                        keepAlive: true
                    }
                    child.material = mat 
                    child.material.needsUpdate = true
                    child.castShadow = false
                    child.receiveShadow = false
                }else{
                    //rest of materials
                    let mat = new THREE.MeshToonMaterial({
                        color: child.material.color
                    })
                    child.material = mat 
                    child.material.needsUpdate = true
                    child.castShadow = true
                    child.receiveShadow = false
                }

                child.material.name = name;
        }

    })
    renderer.shadowMap.needsUpdate = true;
}

const video = document.getElementById( 'video' );
const texture = new THREE.VideoTexture( video );
const videomaterial = new THREE.MeshBasicMaterial( { map: texture } );

const screenGeometry = new THREE.PlaneGeometry(5, 3);
const screen = new THREE.Mesh(screenGeometry, videomaterial)

//non-scaled
screen.position.set(105.764-0.30,10.0087,-7.55885);
screen.rotation.y = -Math.PI / 2
screen.scale.set(1.7,1.7,1.7);

//scaled
// screen.position.set(100.7-0.05,5.5,-7.5335);
// screen.rotation.y = -Math.PI / 2
// screen.scale.set(1.7,1.7,1.7);

scene.add(screen)
video.load();
video.play();

let realScale = false;

/**
 * Models
 */
gltfLoader.load(
    '/models/VID_FEST_WEB_MAP_OP_MAIN_ISLAND.glb',
    (gltf) =>
    {
        updateMaterials(gltf.scene)
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/gusano_anim_02.glb',
    (gltf) =>
    {
        updateMaterials(gltf.scene);
        scene.add(gltf.scene);

        if(realScale){
            gltf.scene.position.y=2.25;
            gltf.scene.position.x=-46;
            gltf.scene.position.z=3.4;
            gltf.scene.rotateY(-Math.PI/2);
        }else{
            gltf.scene.position.y=4.5;
            gltf.scene.position.x=-40;
            gltf.scene.position.z=1;
            gltf.scene.rotateY(-Math.PI/2);
            gltf.scene.scale.set(2,2,2);
        }

        // Animation
        mixer = new THREE.AnimationMixer(gltf.scene)
        const action = mixer.clipAction(gltf.animations[1])
        action.play()
    }
)

gltfLoader.load(
    '/models/copa_anim_06.glb',
    (gltf) =>
    {
        updateMaterials(gltf.scene);
        scene.add(gltf.scene);

        if(realScale){
            gltf.scene.position.y=2.55;
            gltf.scene.position.x=93.5;
            gltf.scene.position.z=-7.5;
            gltf.scene.rotateY(-Math.PI/2);
        }else{
            gltf.scene.position.y=5.5;
            gltf.scene.position.x=93;
            gltf.scene.position.z=-7.5;
            gltf.scene.rotateY(-Math.PI/2);
            gltf.scene.scale.set(2,2,2);
        }
        // Animation
        mixer2 = new THREE.AnimationMixer(gltf.scene)
        const action = mixer2.clipAction(gltf.animations[1])
        action.play()
    }
)

/**
 * Points of interest
 */
const cameraFocusPoints = [
    {   //landing
        position: new THREE.Vector3(183.555, 0, -49.9752),
        radius: 600,
        height: 200
    },
    {   //free stage
        position: new THREE.Vector3(134.252, 0, -43.7116),
        radius: 50,
        height: 30
    },
    {   //paid stage
        position: new THREE.Vector3(270.319, 0, -60.5661),
        radius: 50,
        height: 50
    },
    {   //bodegas
        position: new THREE.Vector3(98.7076, 0, -41.3199),
        radius: 50,
        height: 50
    }
]

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

let cameraTracker = new THREE.Vector3(0,0,0)
let cameraHeight = 50
let cameraOrbitRadius = 50

const cameraMovement = {
    cameraTracker: new THREE.Vector3(0,0,0),
    cameraHeight: 50,
    cameraOrbitRadius: 50
}

cameraMovement.cameraTracker.copy(cameraFocusPoints[0].position)
cameraMovement.cameraHeight = cameraFocusPoints[0].height
cameraMovement.cameraOrbitRadius = cameraFocusPoints[0].radius

window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY
    const newSection = Math.round(scrollY / sizes.height)

    
    if(newSection != currentSection)
    {
        currentSection = newSection
        // console.log(currentSection)

        // // camera.lookAt(cameraFocusPoints[currentSection])
        // camera.lookAt(cameraFocusPoints[currentSection].position)

        // cameraTracker = cameraFocusPoints[currentSection].position

        gsap.to(
        //     // sectionMeshes[currentSection].rotation,
        //     // {
        //     //     duration: 1.5,
        //     //     ease: 'power2.inOut',
        //     //     x: '+=6',
        //     //     y: '+=3',
        //     //     z: '+=1.5'
        //     // }
            cameraMovement.cameraTracker,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: cameraFocusPoints[currentSection].position.x,
                y: cameraFocusPoints[currentSection].position.y,
                z: cameraFocusPoints[currentSection].position.z
            }
        )

        gsap.to(
            cameraMovement,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                cameraHeight: cameraFocusPoints[currentSection].height
            }
        )

        gsap.to(
            cameraMovement,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                cameraOrbitRadius: cameraFocusPoints[currentSection].radius
            }
        )


    }
})

const points = [
    // {
    //     name: "Centro",
    //     position: new THREE.Vector3(-35, 0, 0.35),
    //     element: document.querySelector('.point-0')
    // },
    {
        name: "Escenario Sandevid",
        number: 8,
        position: new THREE.Vector3(-37, 2, 0.728669),
        element: document.querySelector('.point-0')
    },
    {
        name: "Escenario VID Festival",
        number: 15,
        position: new THREE.Vector3(97, 2.5, -7.4693),
        element: document.querySelector('.point-1')
    },
    {
        name: "Zona de Bodegas",
        number: 5,
        position: new THREE.Vector3(-106.5, 1, 14),
        element: document.querySelector('.point-2')
    },
    {
        name: "Acceso",
        number: 9,
        position: new THREE.Vector3(12, 1, 0),
        element: document.querySelector('.point-3')
    },
    {
        name: "Parking",
        number: 16,
        position: new THREE.Vector3(140, 1, -40),
        element: document.querySelector('.point-4')
    },
    {
        name: "Zona Gastronómica <br>\"Ciudad de Daimiel\"",
        number: 4,
        position: new THREE.Vector3(-111.75, 1, -5),
        element: document.querySelector('.point-2')
    },
    {
        name: "Tótem recarga pulseras",
        number: 2,
        position: new THREE.Vector3(-139.75, 1, -5),
        element: document.querySelector('.point-2')
    },
    {
        name: "Tótem recarga pulseras",
        number: 2,
        position: new THREE.Vector3(-83.75, 1, -6),
        element: document.querySelector('.point-2')
    },
    {
        name: "Barra de bebidas",
        number: 6,
        position: new THREE.Vector3(-78.0, 1, -6),
        element: document.querySelector('.point-2')
    },
    {
        name: "Entradas y recargas",
        number: 3,
        position: new THREE.Vector3(-135.25, 1, 14),
        element: document.querySelector('.point-2')
    },
    {
        name: "DeMiranda Shop <br>y Merchandising",
        number: 7,
        position: new THREE.Vector3(-77.75, 1, 13),
        element: document.querySelector('.point-2')
    },
    {
        name: "Aseos",
        number: 1,
        position: new THREE.Vector3(-220, 1, -40),
        element: document.querySelector('.point-2')
    },
    {
        name: "Aseos femeninos + PMR",
        number: 12,
        position: new THREE.Vector3(12, 1, -30),
        element: document.querySelector('.point-2')
    },
    {
        name: "Aseos masculinos",
        number: 17,
        position: new THREE.Vector3(19.75, 1, 31),
        element: document.querySelector('.point-2')
    },
    {
        name: "Foodtrucks",
        number: 13,
        position: new THREE.Vector3(53.5, 1, 28),
        element: document.querySelector('.point-2')
    },
    {
        name: "Zona de descanso",
        number: 14,
        position: new THREE.Vector3(70.5, 1, 19),
        element: document.querySelector('.point-2')
    },
    {
        name: "Barra",
        number: 10,
        position: new THREE.Vector3(55, 1, -19.5),
        element: document.querySelector('.point-2')
    },
    {
        name: "Zona PMR",
        number: 11,
        position: new THREE.Vector3(82, 1, -27),
        element: document.querySelector('.point-2')
    },
    {
        name: "Tótem recarga pulseras",
        number: 2,
        position: new THREE.Vector3(26.5, 1, -7.5),
        element: document.querySelector('.point-2')
    },
    {
        name: "Tótem recarga pulseras",
        number: 2,
        position: new THREE.Vector3(28.5, 1, 4.1),
        element: document.querySelector('.point-2')
    },
    {
        name: "Merchandising",
        number: 18,
        position: new THREE.Vector3(27.5, 1, -1.75),
        element: document.querySelector('.point-2')
    },
]

let mapDiv = document.querySelector("div#mapa");
for(let i = 0; i < points.length; i++){

    let node = document.createElement("div");
    node.classList.add("point");

    let nodeNumber = document.createElement("div");
    nodeNumber.classList.add("number");
    nodeNumber.innerHTML = "";

    let nodeName = document.createElement("div");
    nodeName.classList.add("name");
    nodeName.innerHTML = points[i].name;

    node.appendChild(nodeNumber);
    node.appendChild(nodeName);

    points[i].element = node;
    mapDiv.appendChild(node);

    let labelNode = document.createElement("div");
    labelNode.innerHTML = points[i].number + ". " + points[i].name;
    labelNode.onclick = () => {goTo(i);}

    // const axesHelper = new THREE.AxesHelper( 5 );
    // axesHelper.position.copy(points[i].position)
    // scene.add( axesHelper );
}

const goTo = (value) => {

    //controls.autoRotateSpeed = setup.cameraAutoRotateSpeed *= -1;

    let trakedPoint = new THREE.Vector3(0,0,0)
    let targetPos = new THREE.Vector3(0,0,0)    
    trakedPoint.copy(camera.position)
    targetPos.copy(points[value].position)
    targetPos.x += -80
    targetPos.y += 100
    targetPos.z += Math.sign(controls.autoRotateSpeed)*125

    gsap.to(
        trakedPoint,
        {
            duration: 1.5,
            ease: 'power2.inOut',
            x: targetPos.x,
            y: targetPos.y,
            z: targetPos.z,
            onUpdate: function(){
                camera.position.copy(trakedPoint)
                camera.updateProjectionMatrix()
            }
        }
    )

    let orbitCenter = new THREE.Vector3(0,0,0)
    let targetOrbitCenter = new THREE.Vector3(0,0,0)    
    orbitCenter.copy(controls.target)
    targetOrbitCenter.copy(points[value].position)

    gsap.to(
        orbitCenter,
        {
            duration: 1.5,
            ease: 'power2.inOut',
            x: targetOrbitCenter.x,
            y: targetOrbitCenter.y,
            z: targetOrbitCenter.z,
            onUpdate: function(){
                controls.target.copy(orbitCenter)
                camera.updateProjectionMatrix()
            }
        }
    )
}

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 0.5)
directionalLight.castShadow = setup.shadows
directionalLight.shadow.camera.near = -100
directionalLight.shadow.camera.far = 160
directionalLight.shadow.camera.left = -180
directionalLight.shadow.camera.right  = 200
directionalLight.shadow.camera.top = 140
directionalLight.shadow.camera.bottom = -100
directionalLight.shadow.mapSize.set(2048, 2048)
directionalLight.shadow.normalBias = 0.05
directionalLight.position.set(2, 3, - 3)
scene.add(directionalLight)
//const shadowMapHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
//scene.add(shadowMapHelper);

const ambientLight = new THREE.AmbientLight('#ffffff', 0.5)
scene.add(ambientLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

rendererResize = () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight


    // Update camera
    perspectiveCamera.fov = setup.cameraFov;
    perspectiveCamera.aspect = sizes.width / sizes.height;
    perspectiveCamera.updateProjectionMatrix();
    orthoCamera.left = -setup.cameraFov;
    orthoCamera.right = setup.cameraFov;
    orthoCamera.top = sizes.height/sizes.width*setup.cameraFov;
    orthoCamera.bottom = -sizes.height/sizes.width*setup.cameraFov;
    orthoCamera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    effect.setSize( sizes.width, sizes.height );

    controls.minZoom = 0.3 * Math.min(1,sizes.height / (0.70 *sizes.width))

}

window.addEventListener('resize', rendererResize);

/**
 * Camera
 */
const perspectiveCamera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 500)
const orthoCamera = new THREE.OrthographicCamera( -100, 100, sizes.height/sizes.width*100, -sizes.height/sizes.width*100, -500, 1000 );

perspectiveCamera.updateProjectionMatrix();
orthoCamera.left = -setup.cameraFov;
orthoCamera.right = setup.cameraFov;
orthoCamera.top = sizes.height/sizes.width*setup.cameraFov;
orthoCamera.bottom = -sizes.height/sizes.width*setup.cameraFov;
orthoCamera.updateProjectionMatrix();

let camera = setup.orthoCamera ? orthoCamera : perspectiveCamera;
camera.position.set(-160, 120, 120);
camera.lookAt(points[0].position);
camera.updateProjectionMatrix()
scene.add(orthoCamera);

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.dampingFactor = 0.1
controls.target.set(-60,0,0)
controls.autoRotate = setup.cameraAutoRotate;
controls.autoRotateSpeed = setup.cameraAutoRotateSpeed;
controls.enableZoom = true
controls.enablePan = true
controls.enableRotate = true

controls.minPolarAngle = Math.PI*1.5/8
controls.maxPolarAngle = Math.PI*3.5/8

controls.minZoom = 0.3 * Math.min(1,sizes.height / (0.70 *sizes.width))
controls.maxZoom = 5
controls.minDistance = 10
controls.maxDistance = 500

controls.touches.ONE = THREE.TOUCH.PAN;
controls.touches.TWO = THREE.TOUCH.DOLLY_PAN;
controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;
controls.screenSpacePanning = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMappingExposure = 3
renderer.shadowMap.enabled = true
renderer.shadowMap.autoUpdate = false
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x87CEEB)

let effectParams = {
    defaultThickness: 0.001,
    defaultColor: [0, 0, 0 ],
    defaultAlpha: 1,
    defaultKeepAlive: true // keeps outline material in cache even if material is removed from scene
}

let effect = new OutlineEffect( renderer, effectParams );
//TODO@FERMIN: possible alternative using EffectComposer+OutlinePass
//OutlinePass seems to use gradients to create the outline
//OutlineEffect seems to use new geometry generation
effect.setSize( sizes.width, sizes.height );

let orbitAngle = 0
const cameraOrbit = () => {

        const camPos = new THREE.Vector3() 
        camPos.copy(cameraMovement.cameraTracker)
        
        let orbitRadius = cameraMovement.cameraOrbitRadius
        let orbitHeight = cameraMovement.cameraHeight
        
        camPos.add(new THREE.Vector3(orbitRadius*Math.cos(orbitAngle),orbitHeight,orbitRadius*Math.sin(orbitAngle)))
        camera.position.set(camPos.x, camPos.y, camPos.z)
        camera.lookAt(cameraMovement.cameraTracker)
        
        orbitAngle += 2*Math.PI*(1/144)*(1/40)
    }

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

let mixer = null
let mixer2 = null
renderer.setAnimationLoop(function(){
    stats.begin()
    controls.update()

    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Model animation
    if(mixer) mixer.update(deltaTime)
    if(mixer2) mixer2.update(deltaTime)

    VRcontrols();

    let minX = 9999; 
    let maxX = -9999;
    points.forEach( (e) => {
        const screenPosition = e.position.clone()
        screenPosition.y += 5;
        screenPosition.project(camera)

        const translateX = screenPosition.x * sizes.width * 0.5 - e.element.offsetWidth/2
        const translateY = - screenPosition.y * sizes.height * 0.5 + e.element.offsetHeight/2
        e.element.style.transform = `translate3D(${translateX}px, ${translateY}px, 0)`    

        minX = Math.min(minX, translateX);
        maxX = Math.max(maxX, translateX);
    })

    if(maxX-minX > 2000){
        mapDiv.classList.remove("far");
        mapDiv.classList.add("near");
    }else if(maxX-minX < 500){
        mapDiv.classList.remove("near");
        mapDiv.classList.remove("far");
    }else{
        mapDiv.classList.remove("near");
        mapDiv.classList.add("far");
    }

    //outline effect if enabled in gui
    let cam = (renderer.xr.isPresenting) ? perspectiveCamera : camera;
    if(setup.outline) effect.render(scene, cam)
    else renderer.render(scene, cam)
    
    stats.end()
})

/**
 * Debug GUI
 */
const debug = debugUrl;
const gui = new dat.GUI({ autoPlace: false });
if(debug){
    document.querySelector("#mapa").appendChild(gui.domElement);
    gui.close()
}

// For the save to image function to work,
// preserveDrawingBuffer must be set to true
// when creating the WebGLRenderer
setup.saveAsImage = saveAsImage;
gui.add(setup, "saveAsImage")

const guiOutlineFolder = gui.addFolder("outline");
guiOutlineFolder.add(setup, "outline").name("enabled");
guiOutlineFolder.add(setup, "outlineThickness")
    .name("thickness")
    .min(0).max(0.01)
    .onChange(()=>{
        effectParams.defaultThickness = setup.outlineThickness;
        effect.clear();
        effect = new OutlineEffect( renderer, effectParams );
        effect.setSize( sizes.width, sizes.height );
});

const guiShadowsFolder = gui.addFolder("shadows");
guiShadowsFolder.add(setup, "shadows")
    .name("enabled")
    .onChange(() => {
        directionalLight.castShadow = setup.shadows
        renderer.shadowMap.needsUpdate = true
});
guiShadowsFolder.add(setup, "sunAzimuth")
    .name("azimuth")
    .min(0).max(360)
    .onChange(() => {
        directionalLight.position.set(
            Math.cos(setup.sunAzimuth*Math.PI/180)*Math.cos(setup.sunAltitude*Math.PI/180), 
            Math.sin(setup.sunAltitude*Math.PI/180), 
            Math.sin(setup.sunAzimuth*Math.PI/180)*Math.cos(setup.sunAltitude*Math.PI/180));
        renderer.shadowMap.needsUpdate = true
});
guiShadowsFolder.add(setup, "sunAltitude")
    .name("altitude")
    .min(0).max(90)
    .onChange(() => {
        directionalLight.position.set(
            Math.cos(setup.sunAzimuth*Math.PI/180)*Math.cos(setup.sunAltitude*Math.PI/180), 
            Math.sin(setup.sunAltitude*Math.PI/180), 
            Math.sin(setup.sunAzimuth*Math.PI/180)*Math.cos(setup.sunAltitude*Math.PI/180));
        renderer.shadowMap.needsUpdate = true
})

const guiCameraControlsFolder = gui.addFolder("camera");
guiCameraControlsFolder.add(setup, "cameraFov")
    .name("vfov")
    .min(5).max(120)
    .onChange( value =>{
        perspectiveCamera.fov = value;
        perspectiveCamera.updateProjectionMatrix();
        orthoCamera.left = -value;
        orthoCamera.right = value;
        orthoCamera.top = sizes.height/sizes.width*value;
        orthoCamera.bottom = -sizes.height/sizes.width*value;
        orthoCamera.updateProjectionMatrix();
    });
guiCameraControlsFolder.add(setup, "orthoCamera")
    .onChange( value =>{
        let prevCam = camera
        camera = (value) ? orthoCamera : perspectiveCamera;
        camera.position.copy(prevCam.position);
        camera.rotation.copy(prevCam.rotation);
        camera.updateProjectionMatrix()
        controls.object = camera
    })
guiCameraControlsFolder.add(setup, "cameraAutoRotate")
    .name("autoRotate")
    .onChange( value =>{
        controls.autoRotate = value;
    })
guiCameraControlsFolder.add(setup, "cameraAutoRotateSpeed")
    .name("rotateSpeed")
    .min(-5).max(5)
    // .listen()
    .onChange( value =>{
        controls.autoRotateSpeed = value;
    })

const guiCameraMovementFolder = gui.addFolder("camera movement");
let pointsGuiDropdown = {}
for(let i=0; i<points.length; i++){
    pointsGuiDropdown[points[i].name] = i; 
}    
guiCameraMovementFolder.add(setup, "lookAt",pointsGuiDropdown)
    .onChange( value => {
        goTo(value);
    });

function saveAsImage() {
    var imgData, imgNode;
    var strDownloadMime = "image/octet-stream";

    try {
        var strMime = "image/png"; //or "image/jpeg"
        imgData = renderer.domElement.toDataURL(strMime);

        saveFile(imgData.replace(strMime, strDownloadMime), "vidFestivalMap.png");

    } catch (e) {
        console.log(e);
        return;
    }
}

var saveFile = function (strData, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = strData;
        link.click();
        document.body.removeChild(link); //remove the link when done
    } else {
        location.replace(uri);
    }
}
    
/**
 * FPS counter
 */
const stats = new Stats()
if(debug){
    stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
    stats.dom.className = "statsjs";
    document.querySelector("#mapa").appendChild(stats.dom)
    document.querySelector(".statsjs").style.position = "absolute";
}

/**
 * VR button
 */
const cameraGroup = new THREE.Group()
cameraGroup.add(perspectiveCamera)
scene.add(cameraGroup)

let controller1 = 0;
let controller2 = 0;
let finalDestination = new THREE.Vector3

if(debug){
    renderer.xr.enabled = true;
    canvas.parentElement.appendChild( VRButton.createButton( renderer ) );
    let vrButton = document.querySelector("#VRButton");

    const controllerModelFactory = new XRControllerModelFactory()

    const controllerLeft = renderer.xr.getControllerGrip(0)
    const controllerLeftModel = controllerModelFactory.createControllerModel(controllerLeft)
    controllerLeft.add(controllerLeftModel)
    cameraGroup.add(controllerLeft)

    controller1 = renderer.xr.getController( 0 );
    cameraGroup.add( controller1 );

    controller1.addEventListener( 'connected', (e) => {
    	controller1.gamepad = e.data.gamepad
    })

    const controllerRight = renderer.xr.getControllerGrip(1)
    const controllerRightModel = controllerModelFactory.createControllerModel(controllerRight)
    controllerRight.add(controllerRightModel)
    cameraGroup.add(controllerRight)

    controller2 = renderer.xr.getController( 1 );
    cameraGroup.add( controller2 );

    controller2.addEventListener( 'connected', (e) => {
    	controller2.gamepad = e.data.gamepad
    })
}

//parabola pointer for teleportation
const lineSegments = 10;
const lineGeometry = new THREE.BufferGeometry();
const lineGeometryVertices = new Float32Array((lineSegments +1) * 3);

lineGeometryVertices.fill(0);
lineGeometry.setAttribute('position', new THREE.BufferAttribute(lineGeometryVertices, 3));
const lineMaterial = new THREE.LineBasicMaterial({ color: 0x888888 });
const guideline = new THREE.Line( lineGeometry, lineMaterial );
guideline.frustumCulled = false;
scene.add(guideline)

function showTeleportPointer(controller){

    const parabolaPosAtT = (inVec,t,p,v,g) => {
        inVec.copy(p);    
        inVec.addScaledVector(v,t);
        inVec.addScaledVector(g,0.5*t**2);
        return inVec;
    }    

    const guidingController = renderer.xr.getController( controller );

    const tempVecP = new THREE.Vector3()
    const tempVecV = new THREE.Vector3()
    const tempVec = new THREE.Vector3()

    const p = guidingController.getWorldPosition(tempVecP);
    const v = guidingController.getWorldDirection(tempVecV);
    v.multiplyScalar(-20);

    const g = new THREE.Vector3(0, -9.81, 0)
    const t = (-v.y -Math.sqrt(v.y**2 - 2*p.y*g.y))/g.y;
    console.log("t", t)

    // get the parabola points
    let vertex = tempVec.set(0,0,0);
    for (let i=0; i<=lineSegments; i++) {
        vertex = tempVec.set(0,0,0);
        parabolaPosAtT(vertex,i*t/lineSegments,p,v,g);
        vertex.toArray(lineGeometryVertices,i*3);
        console.log("v",i,vertex)
    }
    finalDestination = vertex

    guideline.visible = true
    guideline.geometry.attributes.position.needsUpdate = true;
}

function move_camera(){

    const tempVec = new THREE.Vector3()

    // feet position, which is the head position but on the ground
    const feetPos = renderer.xr
    .getCamera(camera)
    .getWorldPosition(tempVec);
    feetPos.y = 0;

    const offset = finalDestination.addScaledVector(feetPos ,-1);
    cameraGroup.position.x += offset.x
    cameraGroup.position.z += offset.z
    guideline.visible = false

}

let centered1 = true
let moveTrigger1 = false
let centered2 = true
let moveTrigger2 = false

const VRcontrols = () => {

    if(controller1.gamepad) {
        if(controller1.gamepad.axes[3]<-0.9 && centered1){
            showTeleportPointer(0)
            moveTrigger1 = true
        }else if(controller1.gamepad.axes[2]>0.9 && centered1){
            centered1 = false
            cameraGroup.rotateY(-Math.PI/6)
        }else if(controller1.gamepad.axes[2]<-0.9 && centered1){
            centered1 = false
            cameraGroup.rotateY(Math.PI/6)
        }else if(Math.abs(controller1.gamepad.axes[2])<0.1 && Math.abs(controller1.gamepad.axes[3])<0.1){
            centered1 = true
            if(moveTrigger1){
                moveTrigger1=false
                move_camera()
            }
        }
    }

    if(controller2.gamepad) {
        if(controller2.gamepad.axes[3]<-0.9 && centered2){
            showTeleportPointer(1)
            moveTrigger2 = true
        }else if(controller2.gamepad.axes[2]>0.9 && centered2){
            centered2 = false
            cameraGroup.rotateY(-Math.PI/6)
        }else if(controller2.gamepad.axes[2]<-0.9 && centered2){
            centered2 = false
            cameraGroup.rotateY(Math.PI/6)
        }else if(Math.abs(controller2.gamepad.axes[2])<0.1 && Math.abs(controller2.gamepad.axes[3])<0.1){
            centered2 = true
            if(moveTrigger2){
                moveTrigger2=false
                move_camera()
            }
        }
    }
}